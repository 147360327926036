import bootstrap from 'bootstrap';

const btns = document.querySelectorAll('.scrollTo');
const subjectField = document.querySelector('.js-subject');

function handleButtonClick(id) {
  const scrollToElement = document.getElementById(id);

  scrollToElement.scrollIntoView({block: "center", behavior: "smooth"});
}

btns.forEach(btn => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();

    handleButtonClick(e.target.dataset.id);

    if(!!e.target.dataset.subject) subjectField.value = e.target.dataset.subject;
  });
});

const tabsBtns = document.querySelectorAll('.js-tab-btn');
const tabsInner = document.querySelectorAll('.tabs-inner');
const tabsPreview = document.querySelector('.tabs-preview');

function handleButtonTabClick(index) {
  tabsPreview.classList.remove('active');

  tabsBtns.forEach(btn => btn.classList.remove('active'));
  tabsInner.forEach(btn => btn.classList.remove('active'));

  tabsBtns[index].classList.add('active');
  tabsInner[index].classList.add('active');
}

tabsBtns.forEach((btn, key) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();
    
    handleButtonTabClick(key);
  });
});

const carouselReviewsFade = document.querySelector('#carouselReviewsFade');

const carousel = new bootstrap.Carousel(carouselReviewsFade, {
  interval: 2000,
  wrap: false
});


$(document).ready(function() {
  //open popup
  $('.cd-popup-trigger').on('click', function(event) {
    event.preventDefault();
    $('.cd-popup').addClass('is-visible');
  });
  //close popup
  $('.cd-popup').on('click', function(event) {
    if ($(event.target).is('.cd-popup-close') || $(event.target).is('.cd-popup')) {
      event.preventDefault();
      $(this).removeClass('is-visible');
    }
  });
  //close popup when clicking the esc keyboard button
  $(document).keyup(function(event) {
    if (event.which == '27') {
      $('.cd-popup').removeClass('is-visible');
    }
  });
  $("#order_form").validate({
    rules: {
      name: {
        required: true
      },
      phone: {
        required: true
      },
      email: {
        required: true
      },
      service: {
        required: true
      }
    },
    messages: {
      name: {
        required: 'Укажите Ваше имя'
      },
      phone: {
        required: 'Укажите Ваш телефон'
      },
      email: {
        required: 'Укажите Вашу эл.почту'
      },
      service: {
        required: 'Укажите какая услуга вас интересует'
      }
    },
    submitHandler: function(form) {
      $.ajax({
        type: 'POST',
        url: '/ajax/ajax.php?mth=createOrder',
        data: $(form).serialize(),
        success: function(res) {
          $(form)[0].reset();
          ym(71714845,'reachGoal','ORDER_SAFE')
          $('#open_thanks').trigger('click');

        },
        error: function() {
          alert('Ошибка отправки.');
        }
      });
    }
  });
})